import React from "react";
import JumboBackdrop from "../../Components/JumboBackdrop";
import {Col, Container, Image, Row, Stack} from "react-bootstrap";
import GallerySection from "../../Components/GallerySection";
import OverlayImage from "../../Components/OverlayImage";
import {useContactModal} from "../../Hooks/useContactModal";
import CallToActionSection from "../../Components/CallToActionSection";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";

function Backdrop() {
  return (
    <div className="vw-100 h-75 mt-auto d-flex">
      <Image className="w-100 h-100 object-fit-fill" width="100%" src={Content.contentScreen.jumbo.background}/>
    </div>
  );
}

function Overlay() {
  const {text} = useLanguage();

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center" style={{zIndex: 1}}>
          <h1 className="text-white fw-bold">{text.contentScreen.jumbo.title}</h1>
        </Col>
      </Row>
    </>
  )
}

export default function ContentScreen() {

  const {showBrandModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.contentScreen;
  const content = Content.contentScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>
      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.statement.title.prefix}
              <span className="text-highlight-gradient text-nowrap">{text.statement.title.highlight}</span>
              {text.statement.title.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.statement.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-light position-relative " style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="flex-column-reverse flex-md-row">

            <Col xs={12} md={6}>
              <Stack gap={5}>
                <OverlayImage
                  title={text.collaborativeApproach.conceptAndDesign.title}
                  description={text.collaborativeApproach.conceptAndDesign.description}
                  background={"#b2834e"}
                  image={content.collaborativeApproach.conceptAndDesign}
                />

                <OverlayImage
                  title={text.collaborativeApproach.storyboarding.title}
                  description={text.collaborativeApproach.storyboarding.description}
                  background={"#9870f5"}
                  image={content.collaborativeApproach.storyboarding}
                />

                <OverlayImage
                  title={text.collaborativeApproach.production.title}
                  description={text.collaborativeApproach.production.description}
                  background={"#e55a5a"}
                  image={content.collaborativeApproach.production}
                />
              </Stack>
            </Col>
            <Col className="text-md-start pe-md-5" xs={12} md={6}>
              <div className="sticky-md-top" style={{top: 308, zIndex: 1}}>
                <h1 className="fw-bold">{text.collaborativeApproach.header.title}</h1>
                <p className="text-black-50 me-md-5">
                  {text.collaborativeApproach.header.description}
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>


      <div className="bg-white position-relative" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.tailoredToYou.title.prefix}
              <span className="text-highlight-gradient text-nowrap">{text.tailoredToYou.title.highlight}</span>
              {text.tailoredToYou.title.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.tailoredToYou.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <GallerySection
        className="bg-light"
        imageTopLeft={content.vision.topLeft}
        imageBottomLeft={content.vision.bottomLeft}
        imageTopRight={content.vision.topRight}
        imageBottomRight={content.vision.bottomRight}
        title={text.vision.title}
        description={text.vision.description}
      />

      <CallToActionSection
        className="bg-white"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showBrandModal}
      />
    </>
  );
}
