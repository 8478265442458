import React, {useRef} from "react";
import {Col, Container, Row, Image, Card, Button} from "react-bootstrap";
import JumboBackdrop from "../../Components/JumboBackdrop";
import OverlayImage from "../../Components/OverlayImage";
import CallToActionSection from "../../Components/CallToActionSection";
import {useContactModal} from "../../Hooks/useContactModal";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";

function Backdrop() {
  return (
    <div className="vw-100 h-75 mt-auto d-flex" >
      <Image className="w-100 h-100 object-fit-fill" width="100%" src={Content.creatorScreen.jumbo.background}/>
    </div>
  );
}

function Overlay() {
  const language = useLanguage();
  const {showCreatorModal} = useContactModal();

  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center" style={{zIndex: 1}}>
          <h1 className="text-white fw-bold">{language.text.creatorScreen.jumbo.title}</h1>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Button className="w-100 my-auto py-2 mx-5" style={{zIndex: 1, maxWidth: 400}} onClick={showCreatorModal}>{language.text.buttons.startYourJourney}</Button>
      </Row>
    </>
  )
}


export default function CreatorScreen() {

  const targetRef = useRef<HTMLDivElement>(null);
  const {showCreatorModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.creatorScreen;
  const content = Content.creatorScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>


      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.beRewarded.title.red.prefix}
              <span className="text-danger">{text.beRewarded.title.red.highlight}</span>
              {text.beRewarded.title.red.suffix}
              {text.beRewarded.title.gradient.prefix}
              <span className="text-highlight-gradient">{text.beRewarded.title.gradient.highlight}</span>
              {text.beRewarded.title.gradient.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.beRewarded.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-light position-relative " style={{marginBottom: -1}} ref={targetRef}>
        <Container className="position-relative p-landing-section-1">
          <Row>
            <Col xs={12} md={6} className="d-flex mb-5">
              <h1 className="fw-bold m-auto">{text.whyAgency.title}</h1>
            </Col>
            <Col xs={12} md={6}>
              <OverlayImage
                title={text.whyAgency.growth.title}
                description={text.whyAgency.growth.description}
                background={"#e55a5a"}
                image={content.whyAgency.growth}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <OverlayImage
                title={text.whyAgency.monetise.title}
                description={text.whyAgency.monetise.description}
                background={"#9870f5"}
                image={content.whyAgency.monetise}
              />
            </Col>
            <Col xs={12} md={6} className="mt-4 mt-md-0">
              <OverlayImage
                title={text.whyAgency.opportunities.title}
                description={text.whyAgency.opportunities.description}
                background={"#b2834e"}
                image={content.whyAgency.opportunities}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white vw-100 position-relative">
        <Container>
          <Row style={{paddingTop: 100}}>
            <Col xs={12} className="d-flex justify-content-center">
              <h1 className="fw-bold">
                {text.mindset.title}
              </h1>
            </Col>
          </Row>
          <Row style={{paddingTop: 100, paddingBottom: 100}}>
            <Col xs={12} md={6}>
              <Card className="bg-transparent border-0">
                <div className="w-100 d-flex">
                  <Card.Img src={content.mindset.forCreators} className="w-100 h-100 m-auto object-fit-contain"/>
                </div>
                <Card.Body>
                  <Card.Title><h2>{text.mindset.forCreators.title}</h2></Card.Title>
                  <Card.Text className="text-black-50">
                    {text.mindset.forCreators.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="bg-transparent border-0">
                <div className="w-100 d-flex">
                  <Card.Img src={content.mindset.nonExclusive} className="w-100 h-100 m-auto object-fit-contain"/>
                </div>
                <Card.Body>
                  <Card.Title><h2>{text.mindset.nonExclusive.title}</h2></Card.Title>
                  <Card.Text className="text-black-50">
                    {text.mindset.nonExclusive.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <CallToActionSection
        className="bg-light"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showCreatorModal}
      />
    </>
  );
}
