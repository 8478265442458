export type GallerySet = {
  topLeft: string;
  bottomLeft: string;
  topRight: string;
  bottomRight: string;
};

export type ContentSet = {
  navbar: {
    logo: string;
  },
  footer: {
    logo: string;
  },
  brandModal: {
    form: string;
  },
  creatorModal: {
    form: string;
  }
  homeScreen: {
    jumbo: {
      logo: string;
    },
    rocket: {
      body: string;
      flame: string;
      cloud: string;
    },
    whatDoethaCanDo: {
      influencer: string;
      social: string;
      content: string;
    },
    whyInfluencer: GallerySet,
    whySocial: {
      post: string;
      phone: string;
    },
    content: {
      video: {
        source: string;
        cover: string;
      };
    }
  },
  aboutScreen: {
    whatDeothaProvides: {
      headerIcon: string;
      influencer: string;
      social: string;
      content: string;
    },
    ourClientele: GallerySet,
    local: {
      map: string;
    }
  },
  marketingScreen: {
    jumbo: {
      background: string;
    },
    benefits: {
      benefitOne: string;
      benefitTwo: string;
      benefitThree: string;
      benefitFour: string;
    },
    steps: {
      stepOne: string;
      stepTwo: string;
      stepThree: string;
    },
  },
  socialScreen: {
    jumbo: {
      background: string;
    },
    whySocial: {
      reach: string;
      engagement: string;
      insights: string;
    },
    challengeForBusiness: {
      stack: string;
    }
  },
  contentScreen: {
    jumbo: {
      background: string;
    },
    collaborativeApproach: {
      conceptAndDesign: string;
      storyboarding: string;
      production: string;
    },
    vision: GallerySet
  },
  creatorScreen: {
    jumbo: {
      background: string;
    },
    whyAgency: {
      opportunities: string;
      monetise: string;
      growth: string;
    },
    mindset: {
      forCreators: string;
      nonExclusive: string;
    }
  },
  contactScreen: {
    jumbo: {
      background: string;
    },
    contactUs: {
      split: string;
    }
  },
};

function jsonParseEnv(suffix: string) {
  return JSON.parse(process.env["REACT_APP_CONTENT_" + suffix] || "")
}

export const Content: ContentSet = {
    navbar: jsonParseEnv("NAVBAR"),
    footer: jsonParseEnv("FOOTER"),
    brandModal: jsonParseEnv("BRAND_MODAL"),
    creatorModal: jsonParseEnv("CREATOR_MODAL"),
    homeScreen: jsonParseEnv("HOME_SCREEN"),
    aboutScreen: jsonParseEnv("ABOUT_US_SCREEN"),
    marketingScreen: jsonParseEnv("MARKETING_SCREEN"),
    socialScreen: jsonParseEnv("SOCIAL_SCREEN"),
    contentScreen: jsonParseEnv("CONTENT_SCREEN"),
    creatorScreen: jsonParseEnv("CREATOR_SCREEN"),
    contactScreen: jsonParseEnv("CONTACT_SCREEN")
};
