import React from "react";
import {Container, Row} from "react-bootstrap";
import Marquee from "react-fast-marquee";

export type JumboTextScrollProps = {
  lines?: string[][];
};

export default function JumboTextScroll(props: JumboTextScrollProps) {
  return (
    <Container
      className="position-absolute top-0 bottom-0 left-0 user-select-none pe-none p-0 m-0 text-white"
      fluid
      style={{"--bs-text-opacity": 0.1} as React.CSSProperties}
    >
      {
        props.lines?.map((line, index) => (
          <Row
            className={index % 2 === 0 ? "fw-bolder" : "fw-light"}
            style={{
              marginRight: 1,
              fontSize: 76,
              fontFamily: "Space Mono, monospace",
              fontWeight: "900 !important",
              textTransform: "uppercase",
            }}
          >
            <Marquee gradient={true} direction={index % 2 === 0 ? "left" : "right"} gradientColor={[0, 0, 0]} gradientWidth={50}>
              {
                line.map(item => <span className="me-5">{item}</span>)
              }
            </Marquee>

          </Row>
        ))
      }
    </Container>
  );
}
