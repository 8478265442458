import React from "react";
import {Link, useLocation} from "react-router-dom";
import {Container, Image, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {BsCollectionPlay, BsFilePost, BsGlobe, BsPersonVideo2} from "react-icons/bs";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";


function NavLink(props: { title: string, link: string }) {
  const {languageRoutePath} = useLanguage();

  return (
    <Nav.Link as={Link} to={languageRoutePath(props.link)} href={languageRoutePath(props.link)}>{props.title}</Nav.Link>
  );
}

function DropDownNavLink(props: { title: string, description: string, icon: React.ElementType, link: string }) {
  const {languageRoutePath} = useLanguage();

  return (
    <NavDropdown.Item
      className="px-4 py-3 my-2"
      as={Link}
      to={languageRoutePath(props.link)}
      href={languageRoutePath(props.link)}
    >
      <h5 className="d-flex">
        <props.icon className="my-auto me-3"/>
        {props.title}
      </h5>
      <small className="text-white-50">
        {props.description}
      </small>
    </NavDropdown.Item>
  );
}

function DropDownDivider() {
  return <NavDropdown.Divider className="bg-white opacity-50 mx-4"/>;
}

const DropDownLanguageTitle = React.forwardRef<HTMLAnchorElement, React.HTMLProps<HTMLSpanElement>>(({ children, onClick }, ref) => {
  const {selectedLanguage} = useLanguage();
  return (
    <span
      className="text-white text-decoration-none me-1 align-items-center"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      role="button"
    >
      <BsGlobe size="1.3em" className="text-white-50 me-2"> </BsGlobe> <span>{selectedLanguage}</span>
    </span>
    );
});


function DropDownLanguage({className}: React.AllHTMLAttributes<HTMLDivElement>) {
  const {convertLanguageRoutePath} = useLanguage();
  const location = useLocation();
  return (
    <NavDropdown
      autoClose={true}
      align={{lg: "end"}}
      id="language-nav-dropdown"
      title={<DropDownLanguageTitle/>}
      className={"mt-5 my-lg-auto text-white justify-content-center float-lg-end " + className}
      menuVariant="dark"
    >

        <NavDropdown.Item
          className="px-3 py-2"
          as={Link}
          to={convertLanguageRoutePath(location.pathname, "en")}
        >
          <p className="d-flex m-0">
            English
          </p>
        </NavDropdown.Item>
        <NavDropdown.Item
          className="px-3 py-2"
          as={Link}
          to={convertLanguageRoutePath(location.pathname, "id")}
        >
          <p className="d-flex m-0">
            Bahasa Indonesia
          </p>
        </NavDropdown.Item>

    </NavDropdown>
  );
}

export default function NavBar() {
  const location = useLocation();
  const {text, languageRoutePath} = useLanguage();

  const brandItemActive = [
    languageRoutePath("/marketing"),
    languageRoutePath("/social"),
    languageRoutePath("/content")
  ].includes(location.pathname);

  return (
    <Navbar sticky="top" expand="lg" variant="dark"
            style={{zIndex: 3, "backgroundColor": "rgba(var(--bs-black-rgb), 0.92)", maxHeight: "100vh"} as React.CSSProperties}>
      <Container fluid>
        <Navbar.Brand className="float-start">
          <Image width={52} height={52} src={Content.navbar.logo}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="mx-auto"
            defaultActiveKey="/"
            activeKey={location.pathname}
          >
            <NavLink title={text.navbar.home} link={"/"}/>
            <NavLink title={text.navbar.aboutUs} link={"/about"}/>


            <NavDropdown autoClose title={text.navbar.forBrands} active={brandItemActive}
                         id="basic-nav-dropdown"
                         menuVariant="dark">
              <DropDownNavLink
                title={text.navbar.marketingTitle}
                description={text.navbar.marketingDescription}
                icon={BsPersonVideo2}
                link="/marketing"
              />
              <DropDownDivider/>
              <DropDownNavLink
                title={text.navbar.socialTitle}
                description={text.navbar.socialDescription}
                icon={BsFilePost}
                link="/social"
              />
              <DropDownDivider/>
              <DropDownNavLink
                title={text.navbar.contentTitle}
                description={text.navbar.contentDescription}
                icon={BsCollectionPlay}
                link="/content"
              />
            </NavDropdown>

            <NavLink title={text.navbar.forCreators} link={"/creators"}/>
            <NavLink title={text.navbar.contactUs} link={"/contact"}/>
            <DropDownLanguage className="d-lg-none"/>
          </Nav>


          <DropDownLanguage className="d-none d-lg-flex"/>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
