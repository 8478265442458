import React from "react";
import {Col, Container, Image, Row, Stack} from "react-bootstrap";
import JumboBackdrop from "../../Components/JumboBackdrop";
import OverlayImage from "../../Components/OverlayImage";
import CallToActionSection from "../../Components/CallToActionSection";
import {useContactModal} from "../../Hooks/useContactModal";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";


function Backdrop() {
  return (
    <div className="vw-100 h-75 mt-auto d-flex">
      <Image className="w-100 h-100 object-fit-fill" width="100%" src={Content.socialScreen.jumbo.background}/>
    </div>
  );
}

function Overlay() {
  const {text} = useLanguage();
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center" style={{zIndex: 1}}>
          <h1 className="text-white fw-bold">{text.socialScreen.jumbo.title}</h1>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs={12} md={8} lg={7} style={{zIndex: 1}}>
          <h5 className="text-white-50">
            {text.socialScreen.jumbo.description}
          </h5>
        </Col>
      </Row>
    </>
  )
}

export default function SocialScreen() {

  const {showBrandModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.socialScreen;

  const content = Content.socialScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>
      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.statement.title.prefix}
              <span className="text-highlight-gradient text-nowrap">{text.statement.title.highlight}</span>
              {text.statement.title.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.statement.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-light position-relative " style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="flex-column-reverse flex-md-row">

            <Col xs={12} md={6}>
              <Stack gap={5}>
                <OverlayImage
                  title={text.whySocial.reach.title}
                  description={text.whySocial.reach.description}
                  background={"#b2834e"}
                  image={content.whySocial.reach}
                />

                <OverlayImage
                  title={text.whySocial.engagement.title}
                  description={text.whySocial.engagement.description}
                  background={"#9870f5"}
                  image={content.whySocial.engagement}
                />

                <OverlayImage
                  title={text.whySocial.insights.title}
                  description={text.whySocial.insights.description}
                  background={"#e55a5a"}
                  image={content.whySocial.insights}
                />
              </Stack>
            </Col>
            <Col className="text-md-start pe-md-5" xs={12} md={6}>
              <div className="sticky-md-top" style={{top: 308, zIndex: 1}}>
                <h1 className="fw-bold">{text.whySocial.title}</h1>
                <p className="text-black-50 me-md-5">
                  {text.whySocial.description}
                </p>
              </div>

            </Col>
          </Row>

        </Container>
      </div>

      <div className="bg-white position-relative " style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1 ">
          <Row className="text-md-start">
            <Col xs={12} lg={5} className="d-flex flex-column ">
              <h2 className="fw-bold mb-4 mt-4 mt-lg-auto">
                {text.challengeForBusiness.title}
              </h2>
            </Col>

            <Col xs={12} lg={6} className="offset-lg-1">
              <p className="text-black-50 mb-auto">
                {text.challengeForBusiness.description}
              </p>
            </Col>
          </Row>
          <Row className="solution-stack-row text-start text-md-center h-100">
            <Col xs={12} md={4} className="p-0 d-flex align-items-stretch">
              <Image
                className="w-100 h-100 object-fit-cover"
                src={content.challengeForBusiness.stack}
              />
            </Col>
            <Col xs={12} md={4} className="p-5 bg-opacity-50 bg-secondary d-flex flex-column align-items-stretch">
              <h5 className="fw-bold mt-auto">
                {text.challengeForBusiness.tailoredSolutions.title}
              </h5>
              <p className="text-black-50 mb-auto">
                {text.challengeForBusiness.tailoredSolutions.description}
              </p>
            </Col>
            <Col xs={12} md={4} className="bg-secondary p-5 d-flex flex-column align-items-stretch">
              <h5 className="fw-bold mt-auto">
                {text.challengeForBusiness.rollingManagement.title}
              </h5>
              <p className="text-black-50 mb-auto">
                {text.challengeForBusiness.rollingManagement.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <CallToActionSection
        className="bg-light"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showBrandModal}
      />
    </>
  );
}
