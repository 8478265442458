import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useLanguage} from "../../Hooks/useLanguage";

export type CallToActionSectionProps = {
  title?: string;
  description?: string;
  buttonTitle?: string;
  buttonAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CallToActionSection({className, style, title, description, buttonTitle, buttonAction}: CallToActionSectionProps) {
  const {text} = useLanguage();
  return (
    <div className={"position-relative " + className} style={{marginBottom: -1, ...style}}>
      <Container className="position-relative p-landing-section-1">
        <Row className="">
          <Col xs={12} md={6} className="d-flex flex-column text-md-start">
            <h1 className="fw-bold">{title}</h1>
            <p className="text-black-50">
              {description}
            </p>
          </Col>
          <Col xs={12} md={6} className="d-flex">
            <Button className="w-100 my-auto py-2 mx-5" onClick={buttonAction}>{buttonTitle || text.buttons.startYourJourney}</Button>
          </Col>
        </Row>

      </Container>
    </div>
  );
}
