import React from "react";
import {Modal} from "react-bootstrap";
import {Content} from "../../Models/Content";

export type BrandModalProps = {
  hide?: () => void;
  isVisible?: boolean;
};

export default function BrandModal(props: BrandModalProps) {
  return (
    <Modal show={props.isVisible} size="xl" onHide={props.hide} className="" centered>
      <Modal.Header className="rounded-0" closeButton closeVariant="white">
      </Modal.Header>
      <Modal.Body className="bg-white p-0" style={{height: "80vh"}}>
        <iframe title="Brand interest form" className="w-100 h-100" src={Content.brandModal.form}/>
      </Modal.Body>
    </Modal>
  );
}
