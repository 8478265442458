import React from "react";
import {Col, Container, Row, Image, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BsEnvelope, BsInstagram, BsTiktok, BsYoutube} from "react-icons/bs";
import {Content} from "../../Models/Content";

const links = [
  {
    title: "Deotha",
    items: [
      {
        title: "Home",
        link: "/"
      },
      {
        title: "About Us",
        link: "/about"
      },
      {
        title: "Contact Us",
        link: "/contact"
      }
    ]
  },
  {
    title: "Brands",
    items: [
      {
        title: "Influencer Marketing",
        link: "/marketing"
      },
      {
        title: "Social Media Management",
        link: "/social"
      },
      {
        title: "Content Creation",
        link: "/content"
      },
    ]
  },
  {
    title: "Creators",
    items: [
      {
        title: "For Creators",
        link: "/creators"
      }
    ]
  }
];


export default function Footer() {
  return (
    <div className="w-100 bg-black text-start" style={{paddingTop: 100, paddingBottom: 100}}>
      <Container>

        <Row className="justify-content-center ">
          <Col xs={12} md={4} className="d-flex flex-column">
            <div className="">
              <Image className="w-100" src={Content.footer.logo}/>
            </div>
            <Row className="mt-5 mt-md-4 text-info">
              <Col xs={6} md={12} lg={6} className="mt-2">
                <a className="text-opacity-75 text-secondary text-decoration-none d-flex" href="https://www.instagram.com/deothavirtual">
                  <BsInstagram className="flex-shrink-0 my-auto" size="1.5em"/> <span className="my-auto ms-2">@deothavirtual</span>
                </a>
              </Col>

              <Col xs={6} md={12} lg={6} className="mt-2">
                <a className="text-opacity-75 text-secondary text-decoration-none d-flex" href="https://www.youtube.com/@deothavirtual">
                  <BsYoutube className="flex-shrink-0 my-auto" size="1.5em"/> <span className="my-auto ms-2">@deothavirtual</span>
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={12} lg={6} className="mt-2">
                <a className="text-opacity-75 text-secondary text-decoration-none d-flex" href="https://www.tiktok.com/@deothavirtual">
                  <BsTiktok className="flex-shrink-0 my-auto" size="1.5em"/> <span className="my-auto ms-2">@deothavirtual</span>
                </a>
              </Col>
              <Col xs={6} md={12} lg={6} className="mt-2">
                <a className="text-opacity-75 text-secondary text-decoration-none d-flex" href="mailto:hello@deotha.com">
                  <BsEnvelope className="flex-shrink-0 my-auto" size="1.5em"/> <span className="my-auto ms-2">hello@deotha.com</span>
                </a>
              </Col>
            </Row>

            <div className="d-flex flex-column mt-5 mt-md-2 mb-5 mb-md-0">
              <span className="fw-bold mt-3 text-white">PT. Deotha Digital Teknologi</span>
              <p className="text-white-50 mb-0">
                Jl. Kebon Sirih Timur 1, No. 267, Menteng, Jakarta Pusat 10340
              </p>
            </div>

          </Col>

          {
            links.map((item, index) => (
              <Col className={"d-flex flex-column " + (index === 0 ? "offset-md-2" : "")} >
                  <h5 className="text-white">{item.title}</h5>
                  <Stack className="mt-3 text-white-50 text-decoration-none">
                    {
                      item.items.map(link => (
                        <Link to={link.link} className="text-white-50 text-decoration-none">
                          <p>{link.title}</p>
                        </Link>
                      ))
                    }
                  </Stack>

              </Col>
            ))
          }
        </Row>
        <Row className="justify-content-between">


          <Col xs={3} className="text-white-50 text-end">

          </Col>

        </Row>
      </Container>
    </div>
  );
}
