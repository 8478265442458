import React, {ReactNode, useContext, useMemo, useState} from "react";
import CreatorModal from "../../Components/CreatorModal";
import BrandModal from "../../Components/BrandModal";

export type ContactModalContextType = {
  showCreatorModal: () => void;
  showBrandModal: () => void;
  creatorModalVisible: boolean;
  brandModalVisible: boolean;
};


const ContactModalContext = React.createContext<ContactModalContextType>({} as ContactModalContextType);


export type UploaderProviderPropsType = {
  children: ReactNode;
};

export default function ContactModalProvider(props: UploaderProviderPropsType) {

  const [creatorModalVisible, setCreatorModalVisible] = useState<boolean>(false);
  const [brandModalVisible, setBrandModalVisible] = useState<boolean>(false);

  function showCreatorModal() {
    setBrandModalVisible(false);
    setCreatorModalVisible(true);
  }

  function showBrandModal() {
    setBrandModalVisible(true);
    setCreatorModalVisible(false);
  }

  const memoedValue = useMemo(
    () => ({
      showCreatorModal,
      showBrandModal,
      creatorModalVisible,
      brandModalVisible
    }),
    [creatorModalVisible, brandModalVisible]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <ContactModalContext.Provider value={memoedValue}>
      {props.children}
      <CreatorModal hide={() => setCreatorModalVisible(false)} isVisible={creatorModalVisible}/>
      <BrandModal hide={() => setBrandModalVisible(false)} isVisible={brandModalVisible}/>
      {/*<UploadModal openingState={uploadModalOpeningState} hide={() => setUploadModalOpeningState(undefined)}/>*/}
    </ContactModalContext.Provider>
  );
}


function useContactModal() {
  return useContext(ContactModalContext);
}

export {ContactModalProvider, useContactModal};
