export type Navbar = {
  home: string;
  aboutUs: string;
  forBrands: string;
  marketingTitle: string;
  marketingDescription: string;
  socialTitle: string;
  socialDescription: string;
  contentTitle: string;
  contentDescription: string;
  forCreators: string;
  contactUs: string;
  languages: {
    english: string;
    indonesian: string;
  }
};

export type Footer = {
  deotha: {
    title: string;
    home: string;
    aboutUs: string;
    contactUs: string;
  },
  brands: {
    title: string;
    marketing: string;
    social: string;
    content: string;
  },
  creators: {
    title: string;
    forCreators: string;
  }
};

export type HomeScreen = {
  jumbo: {
    description: string;
  },
  statement: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    description: string;
  },
  whatDeothaCanDoForYou: {
    header: {
      title: string;
      description: string;
    },
    influencer: {
      title: string;
      description: string;
    },
    social: {
      title: string;
      description: string;
    },
    content: {
      title: string;
      description: string;
    }
  },
  whatAreInfluencers: {
    title: string;
    description: string;
  },
  whySocial: {
    title: string;
    description: string;
  },
  content: {
    title: string;
    description: string;
  },
  callToAction: {
    title: string;
    description: string;
  }
};

export type AboutUsScreen = {
  jumbo: {
    title: string;
    description: string;
  },
  whatDeothaProvides: {
    title: string[];
    marketing: {
      title: string;
      description: string;
    },
    social: {
      title: string;
      description: string;
    },
    content: {
      title: string;
      description: string;
    }
  },
  ourClientele: {
    title: string;
    description: string;
  },
  localTouch: {
    title: string;
    description: string;
  },
  ourCommitment: {
    title: string;
    description: string;
  },
  callToAction: {
    title: string;
    description: string;
  }
};

export type MarketingScreen = {
  jumbo: {
    title: string;
    description: string;
  },
  whyInfluencerMarketing: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    description: string;
  },
  benefits: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    benefitOne: {
      title: string;
      description: string;
    },
    benefitTwo: {
      title: string;
      description: string;
    },
    benefitThree: {
      title: string;
      description: string;
    },
    benefitFour: {
      title: string;
      description: string;
    }
  },
  steps: {
    title: string;
    stepOne: {
      stepTitle: string;
      title: string;
      description: string;
    },
    stepTwo: {
      stepTitle: string;
      title: string;
      description: string;
    }
    stepThree: {
      stepTitle: string;
      title: string;
      description: string;
    }
  },
  callToAction: {
    title: string;
    description: string;
  }
};

export type SocialScreen = {
  jumbo: {
    title: string;
    description: string;
  },
  statement: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    description: string;
  },
  whySocial: {
    title: string;
    description: string;
    reach: {
      title: string;
      description: string;
    },
    engagement: {
      title: string;
      description: string;
    }
    insights: {
      title: string;
      description: string;
    }
  },
  challengeForBusiness: {
    title: string;
    description: string;
    tailoredSolutions: {
      title: string;
      description: string;
    },
    rollingManagement: {
      title: string;
      description: string;
    }
  },
  callToAction: {
    title: string;
    description: string;
  }
};

export type ContentScreen = {
  jumbo: {
    title: string;
  },
  statement: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    description: string;
  },
  collaborativeApproach: {
    header: {
      title: string;
      description: string;
    },
    conceptAndDesign: {
      title: string;
      description: string;
    },
    storyboarding: {
      title: string;
      description: string;
    },
    production: {
      title: string;
      description: string;
    }
  },
  tailoredToYou: {
    title: {
      prefix?: string;
      highlight?: string;
      suffix?: string;
    };
    description: string;
  },
  vision: {
    title: string;
    description: string;
  },
  callToAction: {
    title: string;
    description: string;
  }
};

export type CreatorScreen = {
  jumbo: {
    title: string;
  },
  beRewarded: {
    title: {
      red: {
        prefix?: string
        highlight?: string;
        suffix?: string;
      }
      gradient: {
        prefix?: string
        highlight?: string;
        suffix?: string;
      }
    };
    description: string;
  },
  whyAgency: {
    title: string;
    opportunities: {
      title: string;
      description: string;
    },
    monetise: {
      title: string;
      description: string;
    },
    growth: {
      title: string;
      description: string;
    }
  },
  mindset: {
    title: string;
    forCreators: {
      title: string;
      description: string;
    },
    nonExclusive: {
      title: string;
      description: string;
    }
  }
  callToAction: {
    title: string;
    description: string;
  }
};

export type ContactScreen = {
  jumbo: {
    title: string;
  },
  title: string;
  email: {
    title: string;
    value: string;
  },
  whatsApp: {
    title: string;
    value: string;
  },
  address: {
    title: string;
    value: string;
  },
  ourSocials: string;
  workWithUs: {
    title: string;
    brands: string;
    creators: string;
  }
};

export type Buttons = {
  learnMore: string;
  startYourJourney: string;
};

export type SelectedLanguage = {
  navbar: Navbar,
  footer: Footer,
  homeScreen: HomeScreen,
  aboutUs: AboutUsScreen,
  marketingScreen: MarketingScreen,
  socialScreen: SocialScreen,
  contentScreen: ContentScreen,
  creatorScreen: CreatorScreen,
  contactScreen: ContactScreen,
  buttons: Buttons
};

export type Languages = {
  en: SelectedLanguage;
  id: SelectedLanguage;
};

export type AvailableLanguage = keyof Languages;

function jsonParseEnv(suffix: string) {
  return JSON.parse(process.env["REACT_APP_TRANSLATION_" + suffix] || "")
}

export const languageVariants: Languages = {
  en: {
    navbar: jsonParseEnv("EN_NAVBAR"),
    footer: jsonParseEnv("EN_FOOTER"),
    homeScreen: jsonParseEnv("EN_HOME_SCREEN"),
    aboutUs: jsonParseEnv("EN_ABOUT_US_SCREEN"),
    marketingScreen: jsonParseEnv("EN_MARKETING_SCREEN"),
    socialScreen: jsonParseEnv("EN_SOCIAL_SCREEN"),
    contentScreen: jsonParseEnv("EN_CONTENT_SCREEN"),
    creatorScreen: jsonParseEnv("EN_CREATOR_SCREEN"),
    contactScreen: jsonParseEnv("EN_CONTACT_SCREEN"),
    buttons: jsonParseEnv("EN_BUTTONS")
  },
  id: {
    navbar: jsonParseEnv("ID_NAVBAR"),
    footer: jsonParseEnv("ID_FOOTER"),
    homeScreen: jsonParseEnv("ID_HOME_SCREEN"),
    aboutUs: jsonParseEnv("ID_ABOUT_US_SCREEN"),
    marketingScreen: jsonParseEnv("ID_MARKETING_SCREEN"),
    socialScreen: jsonParseEnv("ID_SOCIAL_SCREEN"),
    contentScreen: jsonParseEnv("ID_CONTENT_SCREEN"),
    creatorScreen: jsonParseEnv("ID_CREATOR_SCREEN"),
    contactScreen: jsonParseEnv("ID_CONTACT_SCREEN"),
    buttons: jsonParseEnv("ID_BUTTONS")
  }
}

export default languageVariants;
