import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {SelectedLanguage, languageVariants, AvailableLanguage} from "./Languages";
import {matchPath, useLocation, useMatch} from "react-router-dom";

export type LanguageContextType = {
  readonly text: SelectedLanguage;
  readonly selectedLanguage: AvailableLanguage;
  setLanguage: (language: AvailableLanguage) => void;
  languageRoutePath: (path: string) => string;
  convertLanguageRoutePath: (path: string, newLanguage: AvailableLanguage) => string;
};


const LanguageContext = React.createContext<LanguageContextType>({} as LanguageContextType);


export type LanguageProviderPropsType = {
  children: ReactNode;
};

function filterRawLanguage(raw?: string): AvailableLanguage {
  if (raw !== "id") {
    raw = "en";
  }

  return raw as AvailableLanguage;
}

export default function LanguageProvider(props: LanguageProviderPropsType) {

  const {pathname} = useLocation();
  const match = useMatch({path: "/:language", end: false});
  const rawLanguage = match?.params.language;


  let language = filterRawLanguage(rawLanguage);

  const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguage>(language);

  function validLanguage(value: string) {
    return ["en", "id"].includes(value);
  }

  function setLanguage(language: AvailableLanguage) {
    setSelectedLanguage(language);
  }

  const languageRoutePath = useCallback((path: string) => {
    if (rawLanguage && validLanguage(rawLanguage)) {
      return `/${rawLanguage}${path}`;
    }

    return path;
  }, [rawLanguage]);

  const convertLanguageRoutePath = useCallback((path: string, newLanguage: AvailableLanguage) => {
    if (matchPath({path: "/:language", end: true}, pathname)) {
      return `/${newLanguage}` + path.substring((rawLanguage || "").length+1);
    }
    return `/${newLanguage}` + (rawLanguage && validLanguage(rawLanguage) && !matchPath({path: "/:language", end: true}, pathname) ? path.substring(rawLanguage.length+1) : path);
  }, [rawLanguage, pathname]);

  useEffect(() => {
    setSelectedLanguage(filterRawLanguage(rawLanguage));
  }, [rawLanguage]);

  useEffect(() => {
    document.documentElement.lang = selectedLanguage;
  }, [selectedLanguage]);


  const memoedValue = useMemo(
    () => ({
      text: languageVariants[selectedLanguage],
      setLanguage,
      selectedLanguage,
      languageRoutePath,
      convertLanguageRoutePath
    }),
    [selectedLanguage, languageRoutePath, convertLanguageRoutePath]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <LanguageContext.Provider value={memoedValue}>
      {props.children}
    </LanguageContext.Provider>
  );
}


function useLanguage() {
  return useContext(LanguageContext);
}

export {LanguageProvider, useLanguage};
