import React, {ReactNode} from "react";
import {Container, ContainerProps} from "react-bootstrap";

export type JumboBackdropProps = {
  overlay?: ReactNode;
  backdrop?: ReactNode;
} & React.AllHTMLAttributes<HTMLDivElement> & ContainerProps;

export default function JumboBackdrop({overlay, backdrop, className, fluid, ...props}: JumboBackdropProps) {
  return (
    <Container
      className={"bg-black position-relative overflow-hidden landing-jumbo px-0 text-white-50" + className }
      fluid={fluid || true}
      {...props}
    >
      <Container
        className="d-flex position-absolute top-0 bottom-0 left-0 user-select-none pe-none p-0 m-0 text-white"
        fluid
        style={{"--bs-text-opacity": 0.1, zIndex: 0} as React.CSSProperties}
      >
        {
          backdrop
        }
      </Container>
      <Container style={{zIndex: 1}}>
        {
          overlay
        }
      </Container>
    </Container>
  );
}
