import React from "react";
import {Col, Container, Image, Row, Stack} from "react-bootstrap";
import LearnMoreButton from "../LearnMoreButton";

export type GallerySectionProps = {
  imageTopRight?: string;
  imageTopLeft?: string;
  imageBottomLeft?: string;
  imageBottomRight?: string;
  title?: string;
  description?: string;
  learnMoreLink?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function GallerySection({imageTopLeft, imageBottomLeft, imageTopRight, imageBottomRight, className, learnMoreLink, title, description}: GallerySectionProps) {
  return (
    <div className={"position-relative " + className}>
      <Container>
        <Row style={{paddingTop: 100, paddingBottom: 100}}>
          <Col xs={12} md={6} className="d-flex flex-row">
            <div className="w-50">
              <Stack className="m-3" gap={3}>
                <Image
                  src={imageTopLeft}/>
                <Image
                  src={imageBottomLeft}/>

              </Stack>
            </div>
            <div className="w-50">
              <Stack className="m-3 mt-5" gap={3}>
                <Image
                  src={imageTopRight}/>
                <Image
                  src={imageBottomRight}/>
              </Stack>
            </div>
          </Col>
          <Col xs={12} md={6} className="d-flex">
            <div className="m-auto p-md-5 text-black text-md-start">
              <h3>
                {title}
              </h3>
              <p className="text-black-50">
                {description}
              </p>
              {
                learnMoreLink &&
                <LearnMoreButton to={learnMoreLink}/>
              }
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  );
}
