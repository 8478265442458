import React from "react";
import {Button, Col, Container, Image, Row, Stack} from "react-bootstrap";
import JumboBackdrop from "../../Components/JumboBackdrop";
import {BsInstagram, BsTiktok, BsYoutube} from "react-icons/bs";
import {useContactModal} from "../../Hooks/useContactModal";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";

function Backdrop() {
  return (
    <div className="vw-100 h-75 mt-auto d-flex">
      <Image className="w-100 h-100 object-fit-fill" width="100%" src={Content.contactScreen.jumbo.background}/>
    </div>
  );
}

function Overlay() {
  const {text} = useLanguage();
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center" style={{zIndex: 1}}>
          <h1 className="text-white fw-bold">{text.contactScreen.jumbo.title}</h1>
        </Col>
      </Row>
    </>
  )
}

export default function ContactScreen() {
  const {showBrandModal, showCreatorModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.contactScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>
      <div className="bg-white position-relative rounded-top-5 p-landing-section-1" style={{marginBottom: -1}}>
        <Container>
          <Row className="text-start">

            <Col xs={6} className="d-none d-md-flex p-0">
              <Image
                className="w-100"
                src={Content.contactScreen.contactUs.split}
              />


            </Col>

            <Col xs={12} md={6} className="position-relative p-md-5 contact-column-background">


              <h1 className="fw-bold text-center text-md-start">{text.title}</h1>
              <Stack className="mt-4" gap={4}>
                <div>
                  <p className="text-black-50 mb-0">{text.email.title}</p>
                  <h4>{text.email.value}</h4>
                </div>

                <div>
                  <p className="text-black-50 mb-0">{text.whatsApp.title}</p>
                  <h4>{text.whatsApp.value}</h4>
                </div>

                <div>
                  <p className="text-black-50 mb-0">{text.address.title}</p>
                  <h4>{text.address.value}</h4>
                </div>
              </Stack>

              <h5 className="fw-bold text-center text-md-start" style={{marginTop: 100}}>
                {text.ourSocials}
              </h5>
              <Row>
                <Col xs={12} className="mt-2">
                  <a className="text-decoration-none d-flex" href="https://www.instagram.com/deothavirtual">
                    <BsInstagram className="flex-shrink-0 my-auto" size="1.5em"/> <span
                    className="my-auto ms-2">@deothavirtual</span>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-2">
                  <a className="text-decoration-none d-flex" href="https://www.youtube.com/@deothavirtual">
                    <BsYoutube className="flex-shrink-0 my-auto" size="1.5em"/> <span
                    className="my-auto ms-2">@deothavirtual</span>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mt-2">
                  <a className="text-decoration-none d-flex" href="https://www.tiktok.com/@deothavirtual">
                    <BsTiktok className="flex-shrink-0 my-auto" size="1.5em"/> <span
                    className="my-auto ms-2">@deothavirtual</span>
                  </a>
                </Col>
              </Row>

              <h3 className="fw-bold text-center text-md-start" style={{marginTop: 100}}>{text.workWithUs.title}</h3>
              <Row>
                <Col xs={12} md={6}>
                  <Button onClick={showBrandModal} className="w-100 py-3">
                    {text.workWithUs.brands}
                  </Button>
                </Col>

                <Col xs={12} md={6} className="mt-3 mt-md-0">
                  <Button onClick={showCreatorModal} className="w-100 py-3">
                    {text.workWithUs.creators}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}
