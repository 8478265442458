import React, {useEffect} from 'react';
import './App.css';
import HomeScreen from "./Screens/HomeScreen";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import AboutScreen from "./Screens/AboutScreen";
import {AnimatePresence, AnimatePresenceProps} from "framer-motion";
import CreatorScreen from "./Screens/CreatorScreen";
import ContactScreen from "./Screens/ContactScreen";
import MarketingScreen from "./Screens/MarketingScreen";
import SocialScreen from "./Screens/SocialScreen";
import ContentScreen from "./Screens/ContentScreen";
import Footer from "./Components/Footer";
import ContactModalProvider from "./Hooks/useContactModal";
import NavBar from "./Components/NavBar";
import LanguageProvider from "./Hooks/useLanguage";

function LocationProvider({children}: React.PropsWithChildren<AnimatePresenceProps>) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RouteScrollRestoration(props: { element: React.ReactElement }): React.ReactElement | null {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return props.element;
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (

    <Routes location={location} key={location.key}>

      <Route path=":language?">
        <Route index element={<RouteScrollRestoration element=<HomeScreen/>/>}/>
        <Route path="about" element={<RouteScrollRestoration element=<AboutScreen/>/>}/>
        <Route path="creators" element={<RouteScrollRestoration element=<CreatorScreen/>/>}/>
        <Route path="marketing" element={<RouteScrollRestoration element=<MarketingScreen/>/>}/>
        <Route path="social" element={<RouteScrollRestoration element=<SocialScreen/>/>}/>
        <Route path="content" element={<RouteScrollRestoration element=<ContentScreen/>/>}/>
        <Route path="contact" element={<RouteScrollRestoration element=<ContactScreen/>/>}/>
      </Route>


    </Routes>

  );
}

function App() {

  return (


    <div id="App" className="App bg-black vw-100 position-relative text-center">
      <BrowserRouter>
        <ContactModalProvider>
          <LanguageProvider>

            <NavBar/>

            <LocationProvider>
              <RoutesWithAnimation/>

            </LocationProvider>
            <Footer/>

          </LanguageProvider>
        </ContactModalProvider>

      </BrowserRouter>

    </div>


  );
}

export default App;
