import React from "react";
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {motion, Variants} from "framer-motion";
import CallToActionSection from "../../Components/CallToActionSection";
import {useContactModal} from "../../Hooks/useContactModal";
import {useLanguage} from "../../Hooks/useLanguage";
import JumboBackdrop from "../../Components/JumboBackdrop";
import {Content} from "../../Models/Content";

const cardVariants: Variants = {
  offscreen: {
    y: 100,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    }
  }
};

function Backdrop() {
  return (
    <div className="vw-100 h-75 mt-auto d-flex">
      <Image className="w-100 h-100 object-fit-fill" width="100%" style={{zIndex: 1}} src={Content.marketingScreen.jumbo.background}/>
    </div>
  );
}

function Overlay() {
  const {text} = useLanguage();
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center">
          <h1 className="text-white fw-bold" style={{zIndex: 1}}>{text.marketingScreen.jumbo.title}</h1>
        </Col>
      </Row>
      <Row className="d-flex mt-3 justify-content-center">
        <Col xs={12} md={8} lg={7} style={{zIndex: 1}}>
          <h5 className="text-white-50">{text.marketingScreen.jumbo.description}</h5>
        </Col>
      </Row>
    </>
  )
}

export default function MarketingScreen() {

  const {showBrandModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.marketingScreen;
  const content = Content.marketingScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>

      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.whyInfluencerMarketing.title.prefix}
              <span className="text-highlight-gradient text-nowrap">{text.whyInfluencerMarketing.title.highlight}</span>
              {text.whyInfluencerMarketing.title.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.whyInfluencerMarketing.description}
              </p>
            </Col>



          </Row>
        </Container>
      </div>

      <div className="bg-light position-relative p-landing-section-1" style={{}}>
        <Container className="position-relative ">
          <Row  className="justify-content-evenly my-5" >
            <Col className="d-flex offset-lg-1" xs={12} sm={6} lg={6}>
              <div className="d-flex">
                <h1 className="m-auto fw-bold text-start">
                  {text.benefits.title.prefix}
                  <span className="text-highlight-gradient">{text.benefits.title.highlight}</span>
                  {text.benefits.title.suffix}
                </h1>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-flex "/>

              {
              [
                {image: content.benefits.benefitOne, name: text.benefits.benefitOne.title, description: text.benefits.benefitOne.description},
                {image: content.benefits.benefitTwo, name: text.benefits.benefitTwo.title, description: text.benefits.benefitTwo.description},
                {image: content.benefits.benefitThree, name: text.benefits.benefitThree.title, description: text.benefits.benefitThree.description},
                {image: content.benefits.benefitFour, name: text.benefits.benefitFour.title, description: text.benefits.benefitFour.description},
              ].map(item => (
                <Col xs={12} sm={6} lg={4} as={motion.div} initial="offscreen" viewport={{amount: 0.5}}
                     whileInView="onscreen">
                  <motion.div variants={cardVariants}>
                    <Card className="bg-transparent border-0">
                      <div className="w-100 d-flex" style={{height: 300}}>
                        <Card.Img src={item.image} className="w-100 h-100 m-auto object-fit-contain"/>
                      </div>
                      <Card.Body>
                        <Card.Title><h2>{item.name}</h2></Card.Title>
                        <Card.Text className="text-black-50">
                          {item.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </motion.div>
                </Col>
              ))
            }
          </Row>
        </Container>
      </div>

      <div className="bg-white position-relative" style={{}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="mb-5">
            <h1 className="fw-bold">{text.steps.title}</h1>
          </Row>

          <Row className="justify-content-evenly my-5">

            {
              [
                {image: content.steps.stepOne, ...text.steps.stepOne},
                {image: content.steps.stepTwo, ...text.steps.stepTwo},
                {image: content.steps.stepThree, ...text.steps.stepThree}
              ]
                .map((item) => (
                  <Col xs={12} sm={6} md={3} as={motion.div} initial="offscreen" viewport={{amount: 0.5}}
                       whileInView="onscreen">
                    <motion.div variants={cardVariants}>
                      <h3 className="fw-bold text-black-50 pt-5 text-nowrap">{item.stepTitle}</h3>
                      <Card className="bg-transparent border-0">
                        <div className="w-100 d-flex" style={{height: 300}}>
                          <Card.Img src={item.image} className="w-100 h-100 m-auto object-fit-contain"/>
                        </div>
                        <Card.Body>
                          <Card.Title><h2>{item.title}</h2></Card.Title>
                          <Card.Text className="text-black-50">{item.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </motion.div>

                  </Col>
                ))
            }

          </Row>
        </Container>
      </div>

      <CallToActionSection
        className="bg-light"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showBrandModal}
      />
    </>
  );
}
