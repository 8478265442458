import React from "react";
import {Image, Row} from "react-bootstrap";


export default function OverlayImage(props: { title: string, description?: string, background: string , image?: string}) {
  return (
    <div className="w-100">
      <div
        className={"position-relative border-0 shadow-lg rounded-3 overflow-hidden"}
        style={{minHeight: 300}}
      >
        <Image
          className="rounded-3"
          src={props.image}
        />

        <div className="position-absolute d-flex flex-column rounded-3 top-0 left-0 w-100 h-100 bg-opacity-75"
             style={{backgroundColor: props.background + "BF"}}>
        </div>
        <div className="position-absolute d-flex flex-column top-0 left-0 w-100 h-100 p-3">
          <div className="position-relative d-flex flex-column w-100 h-100">
            <Row className="mb-2">
              <h3 className="text-start fw-bold text-white text">{props.title}</h3>
            </Row>
            <Row className="mt-auto">
              <h5 className="text-start text-white m-0">
                {props.description}
              </h5>
            </Row>
          </div>

        </div>
      </div>
    </div>
  );
}
