import React, {useState} from "react";
import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {motion, Variants} from "framer-motion";
import GallerySection from "../../Components/GallerySection";
import CallToActionSection from "../../Components/CallToActionSection";
import {useContactModal} from "../../Hooks/useContactModal";
import JumboTextScroll from "../../Components/JumboTextScroll";
import JumboBackdrop from "../../Components/JumboBackdrop";
import LearnMoreButton from "../../Components/LearnMoreButton";
import {useLanguage} from "../../Hooks/useLanguage";
import {Content} from "../../Models/Content";
import {BsVolumeMute, BsVolumeUp} from "react-icons/bs";

const cardVariants: Variants = {
  offscreen: {
    y: 100,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    }
  }
};

function Backdrop() {
  return (
    <JumboTextScroll
      lines={[
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],

      ]}
    />
  );
}

function Overlay() {
  const {text} = useLanguage();
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center">
          <Image src={Content.homeScreen.jumbo.logo} style={{width: "100%"}}/>
        </Col>
      </Row>
      <Row className="mt-5">
        <h5 className="text-white-50">{text.homeScreen.jumbo.description}</h5>
      </Row>
    </>
  )
}

export default function HomeScreen() {
  const {showBrandModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.homeScreen;

  const [muted, setMuted] = useState<boolean>(true);
  const content = Content.homeScreen;

  return (
    <>

      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>


      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">
              {text.statement.title.prefix}
              <span className="text-highlight-gradient text-nowrap">{text.statement.title.highlight}</span>
              {text.statement.title.suffix}
            </h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.statement.description}
              </p>
            </Col>

            <div
              className="position-absolute bottom-0 end-0 p-0 overflow-hidden"
              style={{transform: "translateY(50%)", zIndex: 2, width: 150, height: 150}}
            >
              <motion.div
                className="w-100 h-100"
                animate={{y: [-10, 10]}}
                transition={{
                  y: {
                    type: "keyframes",
                    ease: "linear",
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }
                }}
              >
                <div className="w-100 h-100 position-relative">
                  <motion.div
                    className="w-100 h-100"
                    animate={{scale: [0.98, 1.05]}}
                    transition={{
                      scale: {
                        type: "keyframes",
                        ease: "linear",
                        duration: 1,
                        repeat: Infinity,
                        repeatType: "reverse"
                      }
                    }}
                  >
                    <Image src={content.rocket.flame} className="position-absolute top-0 start-0 w-100 h-100"/>
                  </motion.div>
                  <Image src={content.rocket.body} className="position-absolute top-0 start-0 w-100 h-100"/>

                </div>

              </motion.div>
              <Image src={content.rocket.cloud} className="position-absolute top-0 start-0 w-100 h-100"/>

            </div>


          </Row>
        </Container>
      </div>

      <div
        className="bg-light position-relative p-landing-section-1"

      >
        <Container className="position-relative">
          <Row className="">
            <h1 className="fw-bold">{text.whatDeothaCanDoForYou.header.title}</h1>
          </Row>
          <Row className="justify-content-center mt-3 mb-5">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.whatDeothaCanDoForYou.header.description}
              </p>
            </Col>
          </Row>

          <Row className="justify-content-evenly my-5">
            <Col xs={12} sm={6} lg={4} as={motion.div} initial="offscreen" whileInView="onscreen"
                 viewport={{amount: 0.2}}>
              <motion.div className="h-100" variants={cardVariants}>
                <Card className="bg-transparent border-0 h-100">
                  <div className="w-100 d-flex" style={{height: 300}}>
                    <Card.Img
                      src={content.whatDoethaCanDo.influencer}
                      className="w-100 h-100 m-auto object-fit-contain p-5"/>
                  </div>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title><h2>{text.whatDeothaCanDoForYou.influencer.title}</h2></Card.Title>
                    <Card.Text className="text-black-50">
                      {text.whatDeothaCanDoForYou.influencer.description}
                    </Card.Text>
                    <div className="mt-auto">
                      <LearnMoreButton  to="/marketing"/>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            <Col xs={12} sm={6} lg={4} as={motion.div} initial="offscreen" whileInView="onscreen"
                 viewport={{amount: 0.2}}>
              <motion.div className="h-100" variants={cardVariants}>
                <Card className="bg-transparent border-0 h-100">
                  <div className="w-100 d-flex" style={{height: 300}}>
                    <Card.Img
                      src={content.whatDoethaCanDo.social}
                      className="w-100 h-100 m-auto object-fit-contain"/>
                  </div>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title><h2>{text.whatDeothaCanDoForYou.social.title}</h2></Card.Title>
                    <Card.Text className="text-black-50">
                      {text.whatDeothaCanDoForYou.social.description}
                    </Card.Text>
                    <div className="mt-auto">
                      <LearnMoreButton  to="/social"/>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>

            <Col xs={12} sm={6} lg={4} as={motion.div} initial="offscreen" whileInView="onscreen"
                 viewport={{amount: 0.2}}>
              <motion.div className="h-100" variants={cardVariants}>
                <Card className="bg-transparent border-0 h-100">
                  <div className="w-100 d-flex" style={{height: 300}}>
                    <Card.Img
                      src={content.whatDoethaCanDo.content}
                      className="w-100 h-100 object-fit-contain m-auto p-4"/>
                  </div>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title><h2>{text.whatDeothaCanDoForYou.content.title}</h2></Card.Title>
                    <Card.Text className="text-black-50">
                      {text.whatDeothaCanDoForYou.content.description}
                    </Card.Text>
                    <div className="mt-auto">
                      <LearnMoreButton  to="/content"/>
                    </div>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>


          </Row>

        </Container>
      </div>


      <GallerySection
        learnMoreLink="/marketing"
        className="bg-white"
        imageTopLeft={content.whyInfluencer.topLeft}
        imageBottomLeft={content.whyInfluencer.bottomLeft}
        imageTopRight={content.whyInfluencer.topRight}
        imageBottomRight={content.whyInfluencer.bottomRight}
        title={text.whatAreInfluencers.title}
        description={text.whatAreInfluencers.description}
      />


      <motion.div
        className="bg-light position-relative"

      >
        <Container>
          <Row style={{paddingTop: 100, paddingBottom: 100}}>
            <Col xs={12} md={6} className="d-flex flex-column">
              <div className="text-md-start p-5 text-black">
                <h3>
                  {text.whySocial.title}
                </h3>
                <p className="text-black-50">
                  {text.whySocial.description}
                </p>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <LearnMoreButton to="/social"/>
                </div>
              </div>
              <motion.div initial="offscreen" whileInView="onscreen" viewport={{amount: 0.2}} variants={cardVariants}>
                <Image className="w-100 object-fit-contain" src={content.whySocial.post}/>

              </motion.div>

            </Col>

            <Col xs={12} md={6} className="d-none d-md-flex" as={motion.div} initial="offscreen" whileInView="onscreen"
                 viewport={{amount: 0.2}} variants={cardVariants}>
              <Image className="w-100 object-fit-contain" src={content.whySocial.phone}/>
            </Col>


          </Row>
        </Container>
      </motion.div>


      <motion.div
        className="bg-white position-relative"
        initial="offscreen"
        viewport={{amount: 0.2}}
        whileInView="onscreen"
      >
        <Container>
          <Row className="flex-column-reverse flex-md-row" style={{paddingTop: 100, paddingBottom: 100}}>
            <Col xs={12} md={6} className="d-flex flex-column">
              <div className="m-auto text-black text-md-start">
                <h3>
                  {text.content.title}
                </h3>
                <p className="text-black-50">
                  {text.content.description}
                </p>
                <LearnMoreButton to="/content"/>
              </div>

            </Col>
            <Col xs={12} md={6} className="">
              <motion.div variants={cardVariants}>
                <div className="w-100 h-100 position-relative">
                  <video
                    className="w-100 rounded-3 bg-success"
                    loop={true}
                    autoPlay={true}
                    muted={muted}
                    src={content.content.video.source}
                    poster={content.content.video.cover}
                  />
                  <div className="position-absolute top-0 end-0 mt-3 mx-4">
                    <div
                      className="bg-opacity-50 bg-black rounded-circle p-2"
                      role="button"
                      onClick={() => setMuted(prev => !prev)}
                    >
                      {
                        muted ?
                          <BsVolumeMute size="2em" className="text-white"/>
                          :
                          <BsVolumeUp size="2em" className="text-white"/>
                      }

                    </div>
                  </div>
                </div>

              </motion.div>
            </Col>
          </Row>


        </Container>
      </motion.div>


      <CallToActionSection
        className="bg-light"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showBrandModal}
      />


    </>

  );
}
