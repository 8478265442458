import React from "react";
import {Link, LinkProps} from "react-router-dom";
import {useLanguage} from "../../Hooks/useLanguage";

export type LearnMoreButtonProps = {

} & React.AllHTMLAttributes<HTMLDivElement> & LinkProps;

export default function LearnMoreButton({className, ...props}: LearnMoreButtonProps) {
  const {text} = useLanguage();
  return (
    <Link className={"btn btn-primary " + className} {...props}>
      {text.buttons.learnMore}
    </Link>
  );
}
