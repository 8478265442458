import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import GallerySection from "../../Components/GallerySection";
import CallToActionSection from "../../Components/CallToActionSection";
import {useContactModal} from "../../Hooks/useContactModal";
import JumboTextScroll from "../../Components/JumboTextScroll";
import {useLanguage} from "../../Hooks/useLanguage";
import JumboBackdrop from "../../Components/JumboBackdrop";
import {Content} from "../../Models/Content";

function Backdrop() {
  return (
    <JumboTextScroll
      lines={[
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],
        ["DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS", "DEOTHA VIRTUAL", "VTUBERS", "GAMERS"],
        ["INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH", "INFLUENCERS", "BRANDS", "GROWTH"],

      ]}
    />
  );
}

function Overlay() {
  const {text} = useLanguage();
  return (
    <>
      <Row className="justify-content-center">
        <Col xs={7} className="d-flex justify-content-center">
          <h1 className="text-white fw-bold">{text.aboutUs.jumbo.title}</h1>
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col xs={12} md={8} lg={7}>
          <h5 className="text-white-50">{text.aboutUs.jumbo.description}</h5>
        </Col>
      </Row>
    </>
  )
}

export default function AboutScreen() {

  const {showBrandModal} = useContactModal();
  const language = useLanguage();
  const text = language.text.aboutUs;
  const content = Content.aboutScreen;

  return (
    <>
      <JumboBackdrop backdrop={<Backdrop/>} overlay={<Overlay/>}/>


      <div className="bg-white position-relative rounded-top-5" style={{marginBottom: -1}}>

        <Container className="position-relative p-landing-section-1">
          <Row className="text-start align-items-stretch">
            <Col xs={9} md={6}>

              <h1 className="display-1 fw-bold">
                {text.whatDeothaProvides.title.map((item, index) => <>{item} {index < text.whatDeothaProvides.title.length-1 && <br className="d-none d-md-block"/>}</>)}
              </h1>


            </Col>
            <Col xs={3} md={6} className="d-flex justify-content-end">
              <div
                className="my-auto"
                style={{ zIndex: 2, width: 150, height: "auto"}}
              >
                <Image
                  className="w-100 h-100"
                  src={content.whatDeothaProvides.headerIcon}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-start align-items-stretch mt-5">

              <Col className="p-0 mb-md-5" xs={12} md={6}>
                <Image className="w-100 h-100 object-fit-fill" src={content.whatDeothaProvides.influencer}/>
              </Col>
              <Col className="bg-light p-5 mb-5 d-flex flex-column" xs={12} md={6}>
                <h3 className="mt-auto">
                  {text.whatDeothaProvides.marketing.title}
                </h3>
                <p className="text-black-50 mb-auto">
                  {text.whatDeothaProvides.marketing.description}
                </p>
              </Col>
          </Row>


          <Row className="text-start align-items-stretch mt-5">

              <Col className="p-0 mb-0 mb-md-5" xs={12} md={6}>
                <Image className="w-100 h-100 object-fit-fill" src={content.whatDeothaProvides.social}/>
              </Col>
              <Col className="bg-light p-5 mb-5 d-flex flex-column" xs={12} md={6}>
                <h3 className="mt-auto">
                  {text.whatDeothaProvides.social.title}
                </h3>
                <p className="text-black-50 mb-auto">
                  {text.whatDeothaProvides.social.description}
                </p>
              </Col>
          </Row>


          <Row className="text-start align-items-stretch mt-5">

              <Col className="p-0 mb-0 mb-md-5" xs={12} md={6}>
                <Image className="w-100 h-100 object-fit-fill" src={content.whatDeothaProvides.content}/>
              </Col>
              <Col className="bg-light p-5 mb-5 d-flex flex-column" xs={12} md={6}>
                <h3 className="mt-auto">
                  {text.whatDeothaProvides.content.title}
                </h3>
                <p className="text-black-50 mb-auto">
                  {text.whatDeothaProvides.content.description}
                </p>
              </Col>
          </Row>



        </Container>
      </div>

      <GallerySection
        className="bg-light"
        imageTopLeft={content.ourClientele.topLeft}
        imageBottomLeft={content.ourClientele.bottomLeft}
        imageTopRight={content.ourClientele.topRight}
        imageBottomRight={content.ourClientele.bottomRight}
        title={text.ourClientele.title}
        description={text.ourClientele.description}
      />

      <div className="bg-white position-relative">
        <Container>
          <Row className="flex-row-reverse p-landing-section-1">
            <Col xs={12} md={6} className="d-flex flex-row">
              <div className="w-100">
                <iframe title="Deotha Virtual Map" src={content.local.map} className="bg-light w-100" style={{border: 0, height: 400}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </Col>
            <Col xs={12} md={6} className="d-flex">
              <div className="m-auto p-5 text-black text-md-start">
                <h3>
                  {text.localTouch.title}
                </h3>
                <p className="text-black-50">
                  {text.localTouch.description}
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </div>

      <div className="bg-light position-relative" style={{marginBottom: -1}}>
        <Container className="position-relative p-landing-section-1">
          <Row className="">
            <h1 className="fw-bold">{text.ourCommitment.title}</h1>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xs={12} md={8} lg={7}>
              <p className="text-black-50">
                {text.ourCommitment.description}
              </p>
            </Col>
          </Row>
        </Container>
      </div>


      <CallToActionSection
        className="bg-white"
        title={text.callToAction.title}
        description={text.callToAction.description}
        buttonAction={showBrandModal}
      />
    </>
  );
}
